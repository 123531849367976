.wallet-container {
    background-color: #184F90;
    width: 100%;
    height: 520px;
    padding: 20px; /* Add padding for spacing */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: aliceblue; /* Set default text color */
}

.Wallet-heading {
    font-size: 28px;
    color: aliceblue;
    margin-top: -40px;
    text-align: center;
    
}

.wallet-card {
    padding: 20px;
    margin-top: 40px;
    border-radius: 10px;
    width: 65%;
    max-width: 1200px;
    height: auto; /* Adjust to auto to fit content */
    background-color: #fff; /* Background color for card */
    color: #000; /* Text color for card */
    text-align: center;
    cursor: pointer;
}
.createwalletlogo{
    display: flex;
    width: 100px;
    height: 100px;
}

.button-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 20px;
}

.private-key{
    text-align: left;
    font-size: 30px;
    margin-top: -100px;
    margin-left: 150px;
    
}
.Wallet-heading1{
    text-align: left;
    font-size: 18px;
    margin-left: 150px;
}

.wallet-button {
    background-color: #ff9800; /* Orange background */
    color: white; /* White text */
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.wallet-button:hover {
    background-color: #e68900; /* Darker orange on hover */
}

.wallet-message {
    font-size: 18px;
    margin-top: 20px;
}

@media (max-width: 768px) {

    .wallet-container {
        background-color: #184F90;
        width: 100%;
        height: 720px;
        padding: 20px; /* Add padding for spacing */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: aliceblue; /* Set default text color */
    }

    .wallet-card {
        width: 100%; /* Adjust width for smaller screens */
        height: 200px;
        margin-right: 0px;
        margin-top: 30px;
    }

    .createwalletlogo {
        width: 70px; /* Adjust logo size for smaller screens */
        height: 70px;
    }

    .private-key{
        margin-left: 70px;
        margin-top: -80px;

    }
    .Wallet-heading1 {
        margin-left: 70px;
        margin-bottom: 20px;
    }
}

