.transaction-detail-container {
  padding: 20px;
}

.tnxheading {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.txnheading2 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 130px;
}

.flash-logo {
  width: 30px;
  margin-right: 10px;
}

.txn-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.hashlogo {
  width: 18px;
  margin: 0 10px;
}

.txnaddress,
.txnaddress1 {
  font-weight: 500;
  color: #1381bd;
}

.txnaddress1 {
  display: none;
}

.transaction-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 130px;
}
.token-tabs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 60px;
}

.transaction-button {
  padding: 10px 20px;
  cursor: pointer;
  margin: 0 5px;
  background-color: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
}

.transaction-button.active {
  background-color: #ebf8ff;
  color: black;
  border-radius: 8px;
}

.transaction-button:hover {
  color: #007bff;
}

.transaction-detail {
  margin-top: 20px;
}

.text-hash {
  display: flex;
  font-size: 18px;
  margin-top: -40px;
  margin-bottom: 20px;
  margin-left: 400px;
}

.text-hash1 {
  display: none;
}

.trace-content {
  text-align: left;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin: 20px 0;
  max-height: 400px;
  max-width: 100%;
  overflow: auto;
  font-family: monospace;
  white-space: pre-wrap;
  box-sizing: border-box;
}

.transaction-to1{
  display: none;
}
.transaction-copy-to-icon{
  text-align: left;
margin-left: 410px;
margin-top: -26px;
}

.tokencode{
  text-align: left;
  margin-left: 20px;
  font-size: 20px;
}
@media only screen and (max-width: 600px) {
  .txnheading2 {
    margin-left: 0px;
    display: flex;
  }

  .flash-logo {
    margin-bottom: 10px;
  }

  .txn-info {
  margin-left: 0px;
  }

  .txnaddress {
    display: none;
  }

  .txnaddress1 {
    display: block;
  }

  .transaction-tabs {
    margin-left: 10px;
    font-size: 16px;
  }

  .transaction-button {
    padding: 5px;
    font-size: 14px;
    margin: 5px 0;
  }

  .text-hash {
    display: none;
  }

  .text-hash1 {
    display: block;
    margin-right: 20px;
  }
  .hashlogo {
    width: 18px;
    margin-right: 10px;
  }

  .transaction-to{
    display: none;
  }
  .transaction-to1{
    display: flex;
    margin-left: 70px;
    margin-top: -38px;
    font-size: 20px;
    color:#1381bd;

  }
}
