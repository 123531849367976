.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 80%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 90px;
  }
  
  .upload-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
  }
  
  .input-field,
  .input-file {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-button:disabled {
    background-color: #a0a0a0;
    cursor: not-allowed;
  }
  
  .upload-button:hover:enabled {
    background-color: #0056b3;
  }
  
  .message {
    margin-top: 15px;
    color: #2e9227;
  }
  
  .code-container {
    margin-top: 20px;
    width: 100%;
  }
  
  .code-box {
    background-color: #2d2d2d;
    color: #f8f8f2;
    padding: 15px;
    text-align: left;
    border-radius: 4px;
    max-height: 400px;
    overflow-y: scroll;
    white-space: pre-wrap; /* Ensures long lines wrap */
  }
  