/* Parent container for flexbox layout */
.chart-container {
  display: flex;
  justify-content: space-between; /* Space between the two divs */
  align-items: flex-start; /* Align items to the start */
  width: 100%;
  margin-top: 20px; /* Adjust the margin as needed */
}

.cardchart {
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 40%; /* Adjust the width as needed */
  height: 260px;
  margin-left: 170px;
}

.chartdata {
  background-color: rgba(230, 239, 243, 0.596);
  border-radius: 1px;
  box-shadow: 0 4px 8px rgba(200, 18, 18, 0.1);
  padding-right: 90px;
  width: 30%;
  height: 240px;
  padding-top: -20px;
  margin-right: 150px;
}

.heading {
  font-size: 20px;
  margin-bottom: 10px; /* Add margin-bottom for spacing */
  font-weight: 500;
  padding-left: 50px;
}

.DailyTxn{
  display: flex;
  margin-left: 170px;
  font-size: 20px;
  margin-bottom: 10px; /* Add margin-bottom for spacing */
  font-weight: 500;
  color: #718096;

}

.grid-chart {
  padding: 10px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  border: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-left: 60px;
}

.grid-chart:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.chart-title {
  font-size: 15px;
  font-weight: 400;
}

.subtitle{
  color: #718096;

}

.chartlogo {
  width: 25px;
  height: 25px;
  float: left; /* Align image to the left */
}

.view-market-cap {
  padding: 8px 12px;
  background-color: #5b7fa5;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.view-market-cap:hover {
  background-color: #3174ba;
}
@media (max-width: 600px) {
  .chart-container {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center items */
  }

  .chartdata,.cardchart {
    width: 100%; /* Full width on small screens */
    margin: 10px 0; /* Margin for spacing */
  }

  .heading {
    font-size: 22px;
    margin-right: 120px;
  }
  .DailyTxn {
    font-size: 20px;
    margin-bottom: 10px; /* Add margin-bottom for spacing */
    font-weight: 500;
    margin-left: 70px;
  }

  .chartdata {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .grid-chart {
    width: 80%; /* Adjust width for better centering */
    margin-left: 101px; /* Margin for spacing */
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    border: 5px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(7, 135, 158, 0.1);
  }

  .grid-chart:hover {
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}
