.card-body {
    background: rgb(122,152,230);
    background: linear-gradient(90deg, rgba(122,152,230,1) 0%, rgba(19,160,203,1) 100%);
    padding: 20px;
    margin-top: 20px;
    border-radius: 25px;
    width: 85%;
    max-width: 1200px;
    height: 180px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}
.card-title {
    color: rgb(255, 255, 255);
    text-align: left;
    margin-left: 20px;
    margin-top: 0px;
    font-size: 40px;
}


.search-bar {
    margin-top: 20px;
    max-width: calc(100% - 50px); /* Adjusted to allow space for margin and padding */
    height: 50px;
    margin-left: 20px;
    margin-right: 200px;
}

/* Media query for mobile view */
@media (max-width: 767px) {
    .card-body {
        padding-top: 0; /* Adjusted for better spacing */
        height: 130px;
        width: 90%;
        border-radius: 10px;


    }

    .card-title {
        font-size: 24px;
        margin-top: 15px;
        margin-left: 0px;

    }

    .search-bar {
        margin-top: 10px;
        max-width: calc(100% - 40px); /* Adjusted for better spacing */
        margin-left: 0px;
        margin-right: 20px; /* Adjusted for better spacing */
    }
}
