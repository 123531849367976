.Allblock-detail-container {
  padding: 20px;
}

.Allblock-heading {
  display: flex;
  margin-left: 85px;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 500;
}

.Alltransaction-tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab-button {
  margin-right: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.tab-button.active {
  background-color: #EBF8FF;
  color: #2C5282;
}

.Allblock-header {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* 5 columns of equal width */
  margin-bottom: 10px;
  color: rgb(75, 75, 75);
  background-color: #ededed;
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  margin-left: 80px;
  margin-right: 70px;
  gap: 10px;
  /* Add some gap between columns */
}

.Allblock-cards-container {
  display: flex;
  flex-direction: column;
  /* Make sure the cards stack vertically */
  gap: 1px;
}

.Allblock-card {
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
  /* Only show the bottom border */
  padding: 10px;
  margin-left: 80px;
  margin-right: 70px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /* 5 columns of equal width */
  margin-bottom: 1px;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
}

.Allblock-column {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.Allblocknumber-column {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.Allblockaddresslogo {
  text-align: left;
  width: 15px;
  height: 16px;
  margin-right: 5px;
}

.Allblock-time {
  margin-top: 0px;
}

.Allblock-text-size,
.Allblocksize,
.Allblock-text-totaltransaction,
.Allblock-text-gasUsed,
.Allblock-text-miner {
  display: none;
}


.pagination {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-right: 170px;
  margin-top: -50px;
}
.pagination-button, .pagination-button-next {
  cursor: pointer;
  padding: 10px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.pagination-page-first{


  margin-right: 0px;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #317fa9;
  margin-bottom: 10px;
  cursor:pointer
}
.pagination-page-number {
  padding: 10px;
  margin: 0 5px;
  font-size: 1.2em;
}


@media (max-width: 768px) {
  .Allblock-heading {
    margin-left: 20px;
    text-align: left;
  }

  .Alltransaction-tabs {
    justify-content: left;
  }

  .Allblock-header {
    display: none;
  }









    .Allblock-heading {
      margin-left: 20px;
      text-align: left;
    }

    .Alltransaction-tabs {
      justify-content: left;
    }

    .Allblock-header {
      display: none;
    }

    .Allblock-card {
      text-align: left;
      background-color: white;
      border-bottom: 1px solid #e0e0e0;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      border: 0;
      width: 100%;
      height: 220px;
      margin-left: 0px;
      border-radius: 8px;
      box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
      margin-top: 5px;
    }

    .Allblocknumber-column {
      font-weight: bold;
      font-size: 16px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .Allblock-text-size {
      display: flex;
      margin-top: px;
      font-size: 20px;
      color: #414243;
    }

    .Allblocksize-column {
      font-size: 18px;
      margin-top: -30px;
      margin-left: 60px;
      color: #414243;
    }

    .Allblock-text-miner {
      display: flex;
      font-size: 18px;
      margin-left: 0px;
    }

    .Allblock-miner {
      display: flex;
      margin-left: 80px;
      margin-top: -32px;
    }

    .Allblock-text-totaltransaction {
      display: flex;
      font-size: 18px;
      margin-left: 0px;
    }

    .Allblock-totaltransaction {
      margin-left: 40px;
      margin-top: -30px;
    }

    .Allblock-text-gasUsed {
      display: flex;
      font-size: 18px;
    }

    .Allblock-gasUsed {
      margin-left: 80px;
      margin-top: -30px;
    }

    .Allblock-text-timestamp {
      display: flex;
      font-size: 18px;
    }

    .Allblock-time {
      margin-left: 300px;
      margin-top: -184px;
    }

    .Allblockaddresslogo {
      text-align: left;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      margin-top: 7px;
    }


    
.pagination {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-right: 70px;
  margin-top: -0px;
}
.pagination-button, .pagination-button-next {
  cursor: pointer;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
}

.pagination-page-first{


  margin-right: 0px;
  margin-top: 5px;
  padding: 5px 20px;
  border-radius: 10px;
  background-color: #317fa9;
  margin-bottom: 10px;
  cursor:pointer
}
.pagination-page-number {
  padding: 10px;
  margin: 0 5px;
  font-size: 1.2em;
}

  }

 

