.Transactionchart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 1vw;
  flex-wrap: wrap;
  background-color: #ddd;

}


.dataCard {
  background-color: #efefef;
  border-radius: 0.5em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1em;
}

.revenueCard {
  width: 100%;
  height: 20rem;
}

.customerCard {
  width: 100%;
  height: 20rem;
}

.categoryCard {
  width: 100%;
  height: 20rem;
}

.TransactionsandfeePerHour {
  display: flex;
  flex-direction: row; /* Default: side by side */
  align-items: flex-start;
  justify-content: center;
  gap: 2rem; 
  flex-wrap: wrap;
  background-color: #ffffff;
}
.TransactionsandfeePerHour-revenueCard {
  width: 100%;
  height: 30rem;
}
.TransactionsPerHour {
  display: flex;
  flex-direction: row; /* Default: side by side */
  align-items: flex-start;
  justify-content: center;
  gap: 2rem; 
  flex-wrap: wrap;
  background-color: #ffffff;
}

.TransactionsPerHour-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: row; /* Default: side by side */
  align-items: flex-start;
  justify-content: center;
  gap: 2rem; 
  flex-wrap: wrap;
  background-color: #ffffff;
}

.TransactionsPerHour-revenueCard {
  width: 100%;
  height: 25rem;
}

.TransactionsPerHour-dataCard {
  background-color: #ffffff;
  border-radius: 0.5em;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.TransactionsPerHour-card {
  width: 30%;
  height: 20vh;
}


.blockDatacharthome-revenueCard {
  width: 100%;
  height: 30rem;
}


@media (max-width: 768px) {
  .Trnandfee {
    height: 200vh;
  }

  .TransactionsPerHour {
    flex-direction: column; /* Stack elements vertically */
    gap: 0rem; /* Adjust gap to fit better */
    margin-top: 0;

  }

  .TransactionsPerHour-content {
    flex-direction: column; /* Stack chart and card vertically */
    width: 100%; /* Full width for each item */
    gap: 0rem; 
    margin-top: 1rem;
    margin-bottom: 1rem;

  }
  .TransactionsandfeePerHour-revenueCard ,
  .TransactionsPerHour-revenueCard,
  .blockDatacharthome-revenueCard{
    width: 100%; /* Make the chart and card take up full width */
    height: 20rem; /* Adjust height automatically */

  }
  .TransactionsPerHour-card {
    width: 100%; /* Make the chart and card take up full width */
    height: 20vh; /* Adjust height automatically */
  }
 
}

