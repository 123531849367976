.Topaccounts-heading {
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 120px;
}

.Topaccounts-detail {
    margin-right: 100px;
    margin-left: 100px;
}

.Topaccounts-address {
    text-align: left;
    color: #2C5282;
    display: flex;
    align-items: center; /* Ensure the image and text are aligned vertically */
    margin-left: 20px;
    margin-top: -24px;
}

.Topaccounts-address-mobile{
    display: none;
}

.Topaccounts-balance {
    text-align: right;
    display: flex;
    margin-right: 100px;
}

.Topaccounts-Percentage {
    text-align: right;
    display: flex;
    margin-right: 100px;
}

.Topaccounts-address-logo1 {
    width: 15px;
    margin-right: 5px;
}
.Topaccounts-cards,
.Topaccounts-address-logo-mobile,
.Topaccounts-data-mobile{
    display: none;
}

.test-heading{
    text-align: center;
    margin-bottom: 400px; /* Adjust margin for better spacing */
}
/* Media query for screens smaller than 768px (e.g., tablets and mobile devices) */
@media (max-width: 768px) {
    .Topaccounts-heading {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: left;
        margin-left: 10px;
    }
    .Topaccounts-detail{
        display: none;
    }
    .Topaccounts-cards {
        display: flex;
        text-align: left;
        margin-left: 0px;
        margin-top: 20px;
    }
    .Topaccounts-address-mobile{
        display: flex;
        margin-left: 24px;
        margin-top: -20px;
        color: #2C5282;

    }
    .Topaccounts-data-mobile{
        display: flex;
        margin-top: -20px;
    }
    .Topaccounts-address-logo-mobile{
        display: flex;
        width: 15px;
        margin-top: 20px;
    }
    .Topaccounts-address1-logo-mobile{
        width: 15px;
        margin-top: -60px;
    }
    .Topaccounts-address1-mobile{
        display: flex;
        margin-left: 200px;
        margin-top: -54px;
        color: #2C5282;

    }

    .Topaccounts-arror-logo1{
        width: 15px;
        margin-top: -60px;
        margin-left: 154px;
    }
    .Topaccounts-transfer-type{
        display: flex;
        text-align: right;
    }

}