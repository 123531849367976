.block-logo {
    width: 7%;
    height: 5%;
    margin-right: 2px;
    margin-top: -4px;

}



.block-timestamp {
    margin-left: 120px;
    margin-top: -20px;
}

.block-Trx {
    margin-top: -20px;
}



.blockhash {
    color: #2C5282;
    margin-left: 70px;
    margin-top: -22px;
}

.blockhash1 {
    display: none;
    color: #2C5282;
    margin-left: 70px;
    margin-top: -22px;
}
.blockHash-Validator {
    margin-top: -20px;
}

.block-blockNumber{
  color:#2C5282;
}


.block-cards {
    text-align: left;
    margin-left: 80px;
}

.headingTransactions{
  margin-top: 20px;
  margin-bottom: 10px;
}
  
  .Alltransaction-tabs {
    display: flex;
    margin-top: 20px;
    margin-left: 80px;
    color: #2C5282;
  }
  
  /* list */
  
  .Alltransaction-detail{
  margin-left: 70px;
  display: flex;
  }
  

  .transaction-hash{
    color: #2C5282;
    margin-right: 35px;
  }
  
  
  .transaction-type {
    display: flex;
    flex-direction: row; /* This will align the items left-to-right */
    align-items: center;
    margin-left: 10px;
    margin-top: -10px;
  }
  
  .transaction-status {
    color: orange;
    background-color: rgb(239, 229, 209);
    border-radius: 5px;
    padding: 1px 5px 1px 5px; 
    display: inline-block; 
  }
  
  
  .transaction-status1 {
    color: green;
    background-color: rgb(194, 225, 194);
    border-radius: 6px;
    padding: 1px 5px 1px 5px; 
    display: inline-block; 


  }
  
  .Alltransaction-address,
  .Alltransaction-address1 {
    margin-left: 20px;
    margin-top: -22px;
    color: #2C5282;
  }
  
  .transaction-twoaddress {
    text-align: left;
    margin-top: 5px;
    margin-right: 20px;
  
  }
  
  .Alltransaction-address-logo1,
  .Alltransaction-address-logo {
    width: 35px;
    height: 15px;
    padding-right: 20px;
    margin-right: 10px;
    
  }
  
  
  .transactionblock-number{
    display: none;
    margin-left: 0px;
    color: #2C5282;
  }
  

  .alltransactions{
    margin-left: 10px;
    cursor: pointer;
    color: rgb(130, 177, 189);
  }
  
  .transaction-time{
    margin-top: -5px;
  }

  .buttenallblock{
    margin-left: 50px;
    color: rgb(130, 177, 189);
    cursor: pointer;
  }

  .transactionlogo{
    width: 15px;
    text-align: left;
    margin-right: 5px;
  }
  .hash-icon{
    margin-left: 212px;
    margin-top: -22px;
  }

  .from-icon{
    margin-left: 130px;
    font-size: 18px;
    margin-top: -20px;
  }
  .to-icon{
    text-align: right;
    font-size: 18px;
    margin-top: -30px;
    margin-right: 50px;
  }
  .copy-icon{
    cursor: pointer;
  }

  .Tokenchartdatahome{
    display: block;
  }
  .Tokenchartdatahomemobile{
    display: none;
  }
  @media (max-width: 768px) {
    .block-logo {
      width: 4%;
      height: 4%;
     
  
  }
    .Alltransaction-detail{
      margin-left: 50px;
      display: flex;
      text-align: left;
      }

  .block-number{
    margin-top: 75px;
    margin-left: -170px;
  }
  .block-cards {
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
}
.blockhash {
  display: none;
}
.blockhash1 {
    display: flex;
    color: #2C5282;
    margin-left: 80px;
    margin-top: -22px;
}
.block-timestamp {
  text-align: right;

    margin-left: 120px;
    margin-top: -20px;
}
.transaction-body{
  display: none;
}
.Topaccounts-time{
  text-align: right;
  margin-left: 20px;
  margin-top: -24px;
}

.alltransactions{
  text-align: center;
 margin-top: 20px;
 
}
.buttenallblock{
  text-align: center;
  margin-right: 60px;
}



.from-icon{
  margin-left: 135px;

}
  .to-icon{
    margin-right: 20px;
    font-size: 18px;
    margin-top: -30px;
  }

  .Tokenchartdatahome{
    display: none;
  }
  .Tokenchartdatahomemobile{
    display: block;
  }
  }
  