.addressheading {
    text-align: left;
    margin-left: 140px;
    font-size: 28px;
    font-weight: bold;
    color: #433a3a;
}
.alladdresslogo {
    align-items: flex-start;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.alladdress {
    text-align: left;
    margin-left: 140px;
    font-weight: 500;
    font-size: 18px;
}
.alladdress1 {
    display: none;
}
.address-tabs {
    text-align: left;
    margin-left: 80px;
    margin-top: 10px;
    display: flex; /* Use flexbox to align items */
    flex-wrap: nowrap; /* Prevent wrapping to a new line */
    overflow-x: auto; /* Add horizontal scrolling if needed */
}

.address-button {
    border: none; /* Remove border */
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent; /* Remove background color */
    outline: none; /* Remove outline */
    font-weight: 600;
    font-size: 16px;
    position: relative; /* Necessary for the pseudo-element */
    overflow: hidden; /* Hide the sliding effect overflow */
    transition: color 0.3s ease; /* Smooth color transition */
    display: flex;
    align-items: center;
    justify-content: center;
}

.address-button::after {
    content: '';
    position: absolute;
    top: 0;
    right: -100%; /* Start from outside the button */
    width: 100%;
    height: 100%;
    background-color: #ebf8ff; /* Background color for the slider */
    transition: right 0.3s ease; /* Smooth sliding transition */
    z-index: -1; /* Ensure it is behind the text */
}

.address-button.active::after {
    right: 0; /* Slide in from the right */
}

.address-button.active {
    color: black; /* Change text color when active */
    border-radius: 8px;
}

.address-button:hover {
    color: #007bff; /* Change text color on hover */
}


.Token-transfers,
.Tokens,
.Internal-txns {
    margin-bottom: 400px; /* Adjust margin for better spacing */
}

.alladdress-address,
.alladdress-address1 {
  margin-left: 20px;
  margin-top: -22px;
  color: #2C5282;
}

.alladdress-twoaddress {
  text-align: left;
  margin-top: 5px;
  margin-left: 80px;

}

.alladdress-address-logo1,
.alladdress-address-logo {
  width: 35px;
  height: 15px;
  padding-right: 20px;
  margin-right: 80px;
  
}
.alladdress-type {
    display: flex;
    flex-direction: row; /* This will align the items left-to-right */
    align-items: center;
    margin-left: 10px;
  }
  
  .alladdress-status {
    color: orange;
    background-color: rgb(239, 229, 209);
    border-radius: 5px;
    margin-right: 20px;
    margin-top: -10px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-bottom: 1px;
    padding-right: 5px;
  }
  
  .alladdress-status1 {
    color: green;
    background-color: rgb(194, 225, 194);
    border-radius: 6px;
    padding-bottom: 1px;
    padding-left: 5px;
    padding-bottom: 1px;
    padding-right: 5px;
    margin-left: -100px;
    margin-top: 50px;
  }

/* Styles for mobile view */
@media only screen and (max-width: 768px) {
    .addressheading {
        margin-left: 15px;
        font-size: 24px; /* Adjust font size for mobile */
    }
    .alladdresslogo {
        display: flex;
        text-align: left;

        width: 20px;
        height: 20px;
        margin-top: 5px;
        margin-left: -30px; /* Ensure no right margin on mobile */
    }
    .alladdress {
        display: none;
    }
    .alladdress1 {
        display: flex;
        text-align: left;
        margin-left: 50px; /* Reduce margin for mobile */
        margin-top: 23px;
        font-weight: 500;
        font-size: 18px;
    }
    .address-tabs {
        margin-left: 10px; /* Reduce margin for mobile */
        flex-wrap: wrap; /* Allow wrapping in mobile view */
    }
    .address-button {
        margin: 5px 0; /* Adjust margins for mobile */
        padding: 8px 10px; /* Adjust padding for mobile */
        font-size: 14px; /* Adjust font size for mobile */
    }
    .address-arrow1 {
        text-align: center;
        margin: 5px 0;
        margin-top: 14px;
        margin-right: 120px;
        transform: rotate(0deg);
      }
      .alladdress-twoaddress {
        margin-top: 110px;
        margin-left: -230px;
    }
    .alladdress-address1 {
        margin-left: 200px;
        margin-top: -50px;
    }
    .alladdress-address-logo1 {
 
        margin-left: 160px;
        margin-top: -55px;
    }
    .alladdress-type {
        flex-direction: column; /* Change to column direction in mobile view */
        align-items: flex-start; /* Align items to the start in column direction */
        margin-left: -200px;
      }
      
      .alladdress-status{
        margin-left: 30px; /* Remove right margin for mobile view */
        margin-top: 5px; /* Add top margin for spacing in mobile view */
      }
      .alladdress-status1
      {
        display: flex;
        padding-bottom: 2px;
        margin-left: 140px;
        margin-top: 10px;
        margin-top: -25px; /* Add top margin for spacing in mobile view */
      } 
      .alladdress-hash {
        margin-left: 40px;
        margin-top: 40px;
    }
    
    .alladdress-time{
      margin-top: 10px;
      margin-left: -40px;
    }
}
