/* src/Styles/ApiDocumentation.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Adjusted to 100vh for normal viewport */
}

.app {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.vertical-header {
  background-color: #333;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 250px; /* Adjusted width for vertical header */
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  text-align: center;
  transition: background 0.3s;
}

.nav-links a:hover,
.nav-links a.selected {
  background-color: #d24c4c;
  border-radius: 10px; /* Rounded corners for selected and hover states */
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.api-documentation {
  font-family: Arial, sans-serif;
  padding: 20px;
  width: 80%;
  background-color: #f4f4f4;
  border-radius: 8px;
  margin: 20px auto; /* Centering the content horizontally with margin */
}

.api-documentation h1 {
  text-align: center;
  margin-bottom: 20px;
}

.base {
  text-align: left;
  margin-left: 130px;
}

.baseurl,
.baseurl1 {
  color: rgb(79, 127, 169);
  margin-right: 10px;
}

.api-section {
  text-align: left;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.api-section h2 {
  color: #333;
  text-align: left;
}

.Endpoint {
  color: #1e1d1d;
  font-size: 20px;
  margin-top: 10px;
  word-wrap: break-word; /* Added for long text wrapping */
  overflow-wrap: break-word; /* Added for long text wrapping */
}

.requestExample {
  margin-left: 20px;
  color: #466d8e;
  font-size: 20px;
  margin-top: 10px;
}

.api-section pre {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 4px;
  overflow-x: auto;
}

.api-description {
text-align: left;
}

.baseurl {
  font-family: Arial, sans-serif; /* Sets a clean and modern font */
  font-size: 16px;               /* Adjusts the text size */
  color: #0073e6;                /* Sets the text color to blue */
  background-color: #f9f9f9;     /* Light background for contrast */
  padding: 10px;                 /* Adds padding around the text */
  border: 1px solid #ddd;        /* Adds a light border */
  border-radius: 5px;            /* Rounds the corners */
  text-align: center;            /* Centers the text */
  margin: 10px 0;                /* Adds some space above and below */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  max-width: 300px;              /* Limits the width */
  margin-left: auto;             /* Centers the div horizontally */
  margin-right: auto;            /* Centers the div horizontally */
}


/* Mobile view adjustments */
@media (max-width: 768px) {
  .app {
    flex-direction: column; /* Switch to column layout for mobile */
  }

  .vertical-header {
    width: 100%; /* Full width for vertical header on mobile */
    margin-bottom: 20px; /* Add spacing between header and content */
  }

  .api-documentation {
    width: 90%; /* Adjusted width for content on mobile */
    margin: 0 auto; /* Center content horizontally */
    padding: 15px; /* Reduced padding for mobile */
  }

  .nav-links {
    flex-direction: row; /* Change to row layout for mobile navigation */
    justify-content: space-around; /* Distribute links evenly */
  }

  .nav-links a {
    width: auto; /* Allow links to adjust width as per content */
    padding: 8px; /* Reduced padding for mobile */
  }

  .main-content {
    padding: 15px; /* Reduced padding for mobile */
  }
}

/* Footer styling */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}
