 .heading{
    text-align: left;
    margin-left: 70px;
}
.Validatedby {
    display: flex;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    font-weight: 500;
    margin-left: 120px;
}

.blockminerlogo {
    display: flex;
    padding: 0;
    width: 15px;
    height: 16px;
    margin-left: 220px;
    margin-top: -17px;
    margin-bottom: 30px;
}

.mineraddress {
    display: flex;
    margin-top: -52px;
    margin-left: 250px;
    color: #1381bd;
}
.mineraddress1{
    display: none;
}

.block-tabs {
    text-align: left;
    margin-left: 150px;
    margin-top: 10px;
}

.transaction-detail {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    margin-top: 20px;
}

.key {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    margin-left: 90px; 
    margin-bottom: 10px;
    color: #333;

}


.text-value {
    position: relative;
    text-align: left; 
    margin-left: 400px;
    font-size: 18px;
    margin-top: -35px;
    margin-bottom: 10px;
}

.minerlogo {
    text-align: left;
    width: 15px;
    height: 16px;
    margin-right: 5px;
}
.infologo {
    text-align: left;
    width: 15px;
    height: 16px;
    margin-right: 5px;
    margin-bottom: 5px;
}
.timestamp-ist{
    position: relative;
    text-align: right;
    margin-right: 550px; 
    font-size: 18px;
    margin-top: -35px;
    margin-bottom: 10px;
}

  .tab-button {
    border: 20px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 0 5px;
    background-color: transparent; /* Remove background color */
    border: 5px; /* Remove border */
    outline: none; /* Remove outline */
    font-weight: 600;
    font-size: 16px;
    margin-left: -20px;

  }
  
  .tab-button.active {
    background-color: #ebf8ff; /* Background color when active */
    color: black;
    border: 5px;
    border-radius: 8px;
  }
  
  .tab-button:hover {
    color: #007bff; /* Change text color on hover */
  }

/* Styles for mobile view */
@media only screen and (max-width: 600px) {
    .heading{
        text-align: left;
        margin-left: 16px;
    }
    .Validatedby {
        text-align: left;
        margin-left: 16px;
        margin-bottom: 10px;
    }

    .blockminerlogo {
        display: flex;
        padding-left: 0;
        padding-right: 0;
        width: 15px;
        height: 16px;
        margin-left: 120px;
        margin-top: -26px;
    }
    


    .mineraddress {
        display: none;
      
    }
    .mineraddress1 {
        display: flex;
        text-align: left;
        margin-left: 150px;
        margin-top: -50px;
        color: #1381bd;
    }

    .block-tabs {
        text-align: left;
        margin-left: 0;
        margin-top: 20px;
    }

    .transaction-detail {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-top: 0;
    }

    .key {
        text-align: left;
        font-weight: 500;
        font-size: 18px;
        margin-left: 10px; 
        margin-top: 16px;
        margin-bottom: 10px;
    }

    .text-value {
        /* Add mobile styles here */
        position: relative;
        left: 0; 
        text-align: left; 
        font-size: 18px;
        margin-top: 0;
        margin-left: 35px;
        margin-bottom: 10px;
    }

    .minerlogo {
        /* Add mobile styles here */
        text-align: left;
        width: 15px;
        height: 16px;
        margin-right: 5px;
    }

    .infologo {
        text-align: left;
        width: 15px;
        height: 16px;
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .timestamp-ist {
        display: none;      
    }
}
