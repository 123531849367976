.notification {
  background: rgb(122,152,230);
  background: linear-gradient(90deg, rgba(122,152,230,1) 0%, rgba(19,160,203,1) 100%);
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures items are centered vertically */
  padding: 0;
  flex-wrap: wrap; /* Allows items to wrap on small screens */
}

.notificationprice, .notificationgas {
  color: #ebf8ff;
  font-size: 14px;
  margin: 0 10px; /* Adds some margin between the items */
  white-space: nowrap; /* Prevents text from wrapping to a new line */
}




.logo {
  margin-left: 60px;
}

.navbar-header {
  display: flex;
  align-items: center;
}

.blockchainheaderlogo {
  width: 18px;
  height: 18px;
  margin-top: 0px;
  margin-left: 10px;
}

.headertransactionlogo {
  width: 18px;
  height: 18px;
}

.mr-auto {
  margin-left: 240px;
}

@media (max-width: 750px) {
  .notification {
    justify-content: center; /* Centers items in a row on small screens */
  }
  .navbar-header {
    display: flex;
    align-items: center;
  }

  .navbar-toggle {
    order: 1;
  }

  .logo {
    padding-right: 190px;
    order: 0;
    margin-left: 0px;
  }

  .mr-auto {
    margin-left: 0;
    margin-top: 0px;
    margin-left: 20px;
  }

  .navbar-collapse {
    flex-grow: 0;
    text-align: left;
  }

  .blockchainheaderlogo {
    width: 18px;
    height: 18px;
    margin-top: -5px;

  }

  .headertransactionlogo {
    width: 16px;
    height: 16px;
  }
}
/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Popup box styling */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

/* Close icon styling */
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.close-icon:hover {
  color: #ff0000; /* Highlight on hover */
}

/* Countdown styling */
.countdown {
  font-size: 18px;
  font-weight: bold;
  color: #a62121;
  margin: 20px 0;
  padding: 5px; /* Adds some padding inside the card */
  background-color: #f9f9f9; /* Light background for the card */
  border: 2px solid #262373; /* Dark border around the card */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}