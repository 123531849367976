.Alltransaction-detail-container {
  padding: 20px;
}

.Alltransaction-heading {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 80px;
}

.Alltransaction-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-left: 80px;
  margin-right: 80px;
}

.Alltransaction-grid-item {
  background-color: #ebf8ff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.Alltransaction-grid-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Alltransaction-grid-title {
  font-size: 14px;
  margin-bottom: 10px;
  text-align: left;
  color: #718096;
  margin-left: 10px;
  margin-top: 10px;
}

.Alltransaction-grid-value-container {
  position: relative;
  height: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.Alltransaction-grid-value {
  font-size: 20px;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -10px;
}

.Alltransaction-grid-subtitle {
  font-size: 12px;
  color: #718096;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 10px;
}

.Alltransaction-tabs {
  display: flex;
  margin-top: 20px;
  margin-left: 80px;
  color: #2C5282;
}

/* list */

.Alltransaction-detail{
margin-left: 0px;
display: flex;
}

.alltransactionlogo {
  width: 2%;
  height: 2%;
  margin-right: 200px;
  margin-top: 30px;
}
.Alltransaction-hash{
  color: #2C5282;
  margin-left: 30px;
  margin-top: 1rem;
}


.Alltransaction-type {
  display: flex;
  flex-direction: row; /* This will align the items left-to-right */
  align-items: center;
  margin-left: 60px;
  margin-top: -10px;
}

.Alltransaction-status {
  color: orange;
  background-color: rgb(239, 229, 209);
  border-radius: 5px;
  display: inline-block; 

}

.Alltransaction-status1 {
  color: green;
  background-color: rgb(194, 225, 194);
  border-radius: 6px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-bottom: 1px;
  padding-right: 5px;
  margin-top: 30px;
  display: inline-block; 

}

.Alltransaction-address,
.Alltransaction-address1 {
  margin-left: 20px;
  margin-top: -22px;
  color: #2C5282;
}

.Alltransaction-twoaddress {
  text-align: left;
  margin-top: 5px;
  margin-left: 100px;

}
.Alltransaction-transfer{
  margin-left: 20px;
  margin-top: 20px;
    color: rgb(175, 112, 35);
  background-color: rgb(225, 201, 150);
  border-radius: 6px;
  padding-bottom: 1px;
  padding-left: 5px;
  padding-bottom: 1px;
  padding-right: 5px;
  margin-top: 30px;
  display: inline-block; 
}

.Alltransaction-address-logo1,
.Alltransaction-address-logo {
  width: 35px;
  height: 15px;
  padding-right: 20px;
  margin-right: 10px;
  
}


.block-number{
  margin-left: 80px;
  color: #2C5282;
  margin-top: 20px;
}



.Alltransaction-time{
  margin-top: 10px;
  margin-right: 70px;
}

.Alltransaction-cards1,
.Alltransaction-cards{
  display: none;
}



.Alltransaction-amount-fee{
text-align: left;
margin-left: 20px;
}
.Alltransaction-desktop{
  display: flex;
  margin-left: 40px;
    }

    .Alltransaction-grid1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      margin-left: 80px;
      margin-right: 80px;
      margin-bottom: 10px;
    }

@media (max-width: 768px) {

  .Alltransaction-grid {
    grid-template-columns: 1fr;
    margin-left: 5px;
    margin-right: 5px;
    gap: 15px;
  }
  .Alltransaction-grid1 {
    grid-template-columns: 1fr;
    margin-left: 15px;
    margin-right: 15px;
    gap: 15px;
  }

  .Alltransaction-grid-item {
    background-color: #ebf8ff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s, box-shadow 0.3s;
  }

  .Alltransaction-heading {
    font-size: 24px;
    margin-left: 10px;
    text-align: left;
  }

  .Alltransaction-tabs {
    display: flex;
  }

  .Alltransaction-grid-title,
  .Alltransaction-grid-value,
  .Alltransaction-grid-subtitle {
    margin-left: 10px;
  }

  .Alltransaction-grid-value-container {
    margin-bottom: 20px;
    margin-left: 1px;
  }
  .Alltransaction-desktop{
  display: none;
    }
 
    .Alltransaction-cards {
      display: flex;
      text-align: left;
      margin-left: 10px;
      margin-top: 20px;
  }
  .Alltransaction-cards1{
    display: flex;
    text-align: left;
    margin-left: -20px;
    margin-top: 20px;
  }

  .No-contracts{
    text-align: center;
    margin-left: 0px;
    font-size: 28px;
  }

}
